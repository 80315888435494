import React from 'react'

import styles from './ProjectList.module.css'

const ProjectList = ({ children }) => (
  <div className={styles.ProjectList}>
    {React.Children.map(children, (child, i) => (
      <div key={i}>{child}</div>
    ))}
  </div>
)

export default ProjectList
