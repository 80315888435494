import React, { useState, useEffect, useRef } from 'react'

import markdown from '../utils/markdown'
import plainize from '../utils/plainize'
import styles from './TextExpander.module.css'
import stylesText from './Text.module.css'

const TextExpander = ({ text }) => {
  const shortRef = useRef()
  const longRef = useRef()

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (shortRef.current.offsetHeight >= longRef.current.offsetHeight) {
      setExpanded(true)
    }
  }, [])

  return (
    <div
      className={`${styles.TextExpander} ${expanded ? styles.isExpanded : ''}`}
    >
      <button onClick={() => setExpanded(true)}>
        <div className={styles.TextExpander_Short} ref={shortRef}>
          {plainize(text)}
        </div>
        <div className={styles.TextExpander_More}>Read more</div>
      </button>
      <div className={styles.TextExpander_Long} ref={longRef}>
        <div className={stylesText.Text}>{markdown(text)}</div>
      </div>
    </div>
  )
}

export default TextExpander
