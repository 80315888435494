import circOut from 'eases/circ-out'
import circulate from '@hanamura/circulate'
import React, { useEffect, useState, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import styles from './QuickNavTrigger.module.css'

const QuickNavTrigger = () => {
  const length = 17

  const ref = useRef()
  const [offset, setOffset] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    let scrollTop = document.scrollingElement.scrollTop

    const onScroll = () => {
      let scrollOffset = document.scrollingElement.scrollTop - scrollTop
      scrollOffset = Math.min(scrollOffset, 50)
      scrollOffset = Math.max(scrollOffset, -50)
      scrollOffset *= 0.0002
      scrollOffset *= -1
      scrollTop = document.scrollingElement.scrollTop
      setOffset(offset => offset + scrollOffset)
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    const observer = new ResizeObserver(() =>
      setHeight(ref.current.offsetHeight)
    )
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return (
    <div className={styles.QuickNavTrigger} ref={ref}>
      {Array(length)
        .fill(0)
        .map((_, i) => {
          const ratio = circulate(i / length + offset, 0, 1)
          const y = ratio * height

          let opacity
          if (ratio < 0.5) {
            opacity = circOut(ratio / 0.5)
          } else {
            opacity = circOut((0.5 - ratio + 0.5) / 0.5)
          }

          return (
            <div
              style={{
                opacity,
                transform: `translateY(${y}px)`
              }}
              key={i}
            />
          )
        })}
    </div>
  )
}

export default QuickNavTrigger
