import React from 'react'

import styles from './ProjectContainer.module.css'

const ProjectContainer = ({ children }) => (
  <div className={styles.ProjectContainer}>
    {React.Children.map(children, (child, i) => (
      <div key={i}>{child}</div>
    ))}
  </div>
)

export default ProjectContainer
