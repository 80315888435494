import breaks from 'remark-breaks'
import html from 'remark-html'
import remark from 'remark'
import sanitize from 'sanitize-html'

export default text =>
  sanitize(
    remark()
      .use(breaks)
      .use(html)
      .processSync(text).contents,
    { allowedTags: [] }
  )
