import { Motion, spring } from 'react-motion'
import React, { useEffect, useState, useRef } from 'react'

import isTouchDevice from '../utils/isTouchDevice'
import styles from './QuickNavAnchors.module.css'

const QuickNavAnchors = ({ nodes, position, active, onTerminate }) => {
  const ref = useRef()

  const [scrollTop, setScrollTop] = useState(0)
  const [selectedSlug, setSelectedSlug] = useState(null)

  useEffect(() => {
    const scrollLength = ref.current.scrollHeight - ref.current.offsetHeight
    setScrollTop(position * scrollLength)
  }, [position])

  useEffect(() => {
    if (!active) return

    let coordinate = null

    const onTouchMove = ({ touches }) => {
      const touch = touches[0]
      coordinate = [touch.clientX, touch.clientY]
      focus()
    }

    const onTouchEnd = () => {
      if (!coordinate) return

      const el = document.elementFromPoint(...coordinate)

      if (ref.current.contains(el) && el.dataset.slug) {
        setSelectedSlug(el.dataset.slug)
        const targetEl = document.getElementById(el.dataset.slug)
        targetEl && targetEl.scrollIntoView(true)
      } else {
        setSelectedSlug(null)
      }
    }

    const onMouseMove = e => {
      coordinate = [e.clientX, e.clientY]
      focus()
    }

    const onFrame = () => {
      requestId = requestAnimationFrame(onFrame)
      focus()
    }

    const focus = () => {
      if (!coordinate) return

      const el = document.elementFromPoint(...coordinate)

      if (ref.current.contains(el) && el.dataset.slug) {
        setSelectedSlug(el.dataset.slug)
      } else {
        setSelectedSlug(null)
      }
    }

    document.addEventListener('touchmove', onTouchMove, { passive: false })
    document.addEventListener('touchend', onTouchEnd)
    isTouchDevice() || document.addEventListener('mousemove', onMouseMove)
    let requestId = requestAnimationFrame(onFrame)

    return () => {
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('mousemove', onMouseMove)
      cancelAnimationFrame(requestId)
    }
  }, [active])

  useEffect(() => {
    if (!selectedSlug) return
    if (isTouchDevice()) return
    const el = document.getElementById(selectedSlug)
    el && el.scrollIntoView(true)
  }, [selectedSlug])

  useEffect(() => {
    const el = ref.current
    const onWheel = e => e.preventDefault()
    el.addEventListener('wheel', onWheel, { passive: false })
    return () => el.removeEventListener('wheel', onWheel)
  }, [])

  const onClick = e => {
    e.preventDefault()
    if (!isTouchDevice() && onTerminate) {
      onTerminate()
    }
  }

  const onClickOutside = e => {
    if (!isTouchDevice() && !(e.target instanceof HTMLAnchorElement)) {
      onTerminate()
    }
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <nav className={styles.QuickNavAnchors} ref={ref} onClick={onClickOutside}>
      <Motion style={{ scrollTop: spring(scrollTop) }}>
        {({ scrollTop }) => {
          ref.current && (ref.current.scrollTop = scrollTop)
          return null
        }}
      </Motion>
      <ul>
        <li className={styles.isTop}>
          <a
            className={
              active && selectedSlug === '__top' ? styles.isSelected : ''
            }
            href="#__top"
            data-slug="__top"
            onClick={onClick}
          >
            Projects - Top
          </a>
        </li>
        {nodes.map((node, i) => (
          <li key={i}>
            <a
              className={
                active && selectedSlug === node.slug ? styles.isSelected : ''
              }
              href={`#${node.slug}`}
              data-slug={node.slug}
              onClick={onClick}
            >
              {node.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-enable jsx-a11y/click-events-have-key-events */
}

export default QuickNavAnchors
