import React, { useState } from 'react'

import ImageList from './ImageList'
import TextExpander from './TextExpander'

import markdown from '../utils/markdown'
import splitChars from '../utils/splitChars'

import styles from './ProjectItem.module.css'

const ProjectItem = ({ node }) => {
  const [index, setIndex] = useState(0)

  return (
    <article className={styles.ProjectItem}>
      <span className={styles.ProjectItem_Anchor} id={node.slug} />
      <h1>
        <a href={`#${node.slug}`}>{splitChars(node.name)}</a>
      </h1>
      <div className={styles.ProjectItem_Meta}>
        <div className={styles.ProjectItem_PeriodCategory}>
          <span>{node.period}</span>
          <span>{node.category}</span>
        </div>
        <div className={styles.ProjectItem_Credit}>{markdown(node.credit)}</div>
      </div>
      {node.description && (
        <div className={styles.ProjectItem_Description}>
          <TextExpander text={node.description} />
        </div>
      )}
      {node.images.length >= 2 && (
        <div className={styles.ProjectItem_Indicator}>
          {node.images.map((_, i) => (
            <div className={index === i ? styles.isActive : ''} key={i} />
          ))}
        </div>
      )}
      <div className={styles.ProjectItem_ImageList}>
        <ImageList images={node.images} onIndexChange={setIndex} />
      </div>
    </article>
  )
}

export default ProjectItem
