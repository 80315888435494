import { graphql } from 'gatsby'
import React, { useState } from 'react'

import CoverList from '../components/CoverList'
import Layout from '../components/Layout'
import ProjectContainer from '../components/ProjectContainer'
import ProjectItem from '../components/ProjectItem'
import ProjectList from '../components/ProjectList'
import QuickNav from '../components/QuickNav'

const IndexPage = ({ data, location }) => {
  const nodes = data.allProjectsYaml.edges.map(({ node }) => node)
  const coverNodes = nodes.filter(node => node.pickup)

  const [quickNavHidden, setQuickNavHidden] = useState(true)

  return (
    <Layout location={location}>
      <QuickNav nodes={nodes} hidden={quickNavHidden} />
      <ProjectContainer>
        <CoverList
          nodes={coverNodes}
          onIntersectionChange={intersecting => setQuickNavHidden(intersecting)}
        />
        <ProjectList>
          {nodes.map((node, i) => (
            <ProjectItem node={node} key={i} />
          ))}
        </ProjectList>
      </ProjectContainer>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allProjectsYaml(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          slug
          name
          period
          category
          credit
          description
          pickup
          coverImage: image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          images {
            image {
              publicURL
              childImageSharp {
                resize(width: 750) {
                  src
                  height
                  width
                }
              }
            }
            caption
            small
          }
        }
      }
    }
  }
`
