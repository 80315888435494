import debounce from 'lodash.debounce'
import React, { useRef, useEffect, useState } from 'react'

import styles from './ImageList.module.css'

const mediaQueryList =
  typeof matchMedia === 'function' ? matchMedia('(min-width: 700px)') : null // avoiding Gatsby Webpack error: https://www.gatsbyjs.org/docs/debugging-html-builds/

let observer
const listeners = new WeakMap()

const getObserver = () => {
  if (!observer) {
    observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        const cb = listeners.get(entry.target)
        cb(entry.isIntersecting || entry.intersectionRatio > 0)
      }
    })
  }
  return observer
}

const observe = (el, cb) => {
  const observer = getObserver()
  observer.observe(el)
  listeners.set(el, cb)
}

const unobserve = el => {
  const observer = getObserver()
  observer.unobserve(el)
  listeners.delete(el)
}

const ImageList = ({ images, onIndexChange }) => {
  const ref = useRef()
  const [height, setHeight] = useState(0)
  const [lazyloadStarted, setLazyloadStarted] = useState(false)
  const [lazyloadLoaded, setLazyloadLoaded] = useState(false)

  useEffect(() => {
    const el = ref.current

    let index = 0

    const update = () => {
      let currPosition = el.scrollLeft / el.offsetWidth
      currPosition = Math.max(currPosition, 0)
      currPosition = Math.min(currPosition, images.length - 1)
      const currIndex = Math.round(currPosition)
      if (currIndex !== index) {
        index = currIndex
        onIndexChange && onIndexChange(currIndex)
      }
    }

    const updateHeight = () => {
      let currPosition = el.scrollLeft / el.offsetWidth
      currPosition = Math.max(currPosition, 0)
      currPosition = Math.min(currPosition, images.length - 1)

      if (mediaQueryList.matches) {
        setHeight(0)
      } else {
        const leftIndex = Math.floor(currPosition)
        const rightIndex = Math.ceil(currPosition)
        const leftHeight = el.children[leftIndex].offsetHeight
        const rightHeight = el.children[rightIndex].offsetHeight
        const height = Math.max(leftHeight, rightHeight)
        setHeight(height)
      }
    }

    const debouncedUpdateHeight = debounce(updateHeight, 100)

    el.addEventListener('scroll', update)
    el.addEventListener('scroll', debouncedUpdateHeight)
    mediaQueryList.addListener(updateHeight)

    update()
    updateHeight()

    return () => {
      el.removeEventListener('scroll', update)
      el.removeEventListener('scroll', debouncedUpdateHeight)
      mediaQueryList.removeListener(updateHeight)
    }
  }, [images, onIndexChange])

  useEffect(() => {
    const el = ref.current

    const onIntersectionChange = intersecting => {
      if (intersecting) {
        unobserve(el)
        setLazyloadStarted(true)
      }
    }
    observe(el, debounce(onIntersectionChange, 500))

    return () => unobserve(el)
  }, [])

  return (
    <div
      className={styles.ImageList}
      style={{ height: height > 0 ? height : 'auto' }}
      ref={ref}
    >
      {images.map((image, i) => (
        <figure className={image.small ? styles.isSmall : ''} key={i}>
          <div
            style={{
              paddingTop: `${(image.image.childImageSharp.resize.height /
                image.image.childImageSharp.resize.width) *
                100}%`
            }}
          >
            {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className={lazyloadLoaded ? styles.isLoaded : ''}
              src={
                lazyloadStarted ? image.image.childImageSharp.resize.src : ''
              }
              srcSet={
                lazyloadStarted
                  ? `${image.image.childImageSharp.resize.src} 750w, ${image.image.publicURL} 1440w`
                  : ''
              }
              sizes="100vw, (min-width: 700px) 1440px"
              alt=""
              onLoad={() => setLazyloadLoaded(true)}
            />
            {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
          </div>
          {image.caption && <figcaption>{image.caption}</figcaption>}
        </figure>
      ))}
    </div>
  )
}

export default ImageList
