import { Motion, spring } from 'react-motion'
import React, { useEffect, useRef, useState } from 'react'

import Image from './Image'
import styles from './CoverList.module.css'
import stylesImage from './CoverImage.module.css'

const CoverList = ({ nodes, onIntersectionChange }) => {
  const ref = useRef()
  const scrollerRef = useRef()
  const [index, setIndex] = useState(0)
  const [scroll, setScroll] = useState(0)
  const [currentScroll, setCurrentScroll] = useState(0)
  const [animating, setAnimating] = useState(false)

  useEffect(() => {
    const scrollEl = scrollerRef.current
    const onScroll = () => {
      const scroll = scrollEl.scrollLeft
      const children = scrollEl.children
      const interval = children[1].offsetLeft - children[0].offsetLeft
      const index = Math.floor((scroll + interval / 2) / interval)
      setIndex(index)
    }

    scrollEl.addEventListener('scroll', onScroll)
    return () => scrollEl.removeEventListener('scroll', onScroll)
  }, [index])

  useEffect(() => {
    let isIntersecting = true
    const observer = new IntersectionObserver(entries => {
      const intersecting =
        entries[0].isIntersecting || entries[0].intersectionRatio > 0
      if (intersecting !== isIntersecting) {
        isIntersecting = intersecting
        onIntersectionChange(intersecting)
      }
    })
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [onIntersectionChange])

  const scrollTo = index => {
    const children = scrollerRef.current.children
    const offset = children[0].offsetLeft
    const target = children[index]
    const scroll = target.offsetLeft - offset
    setCurrentScroll(scrollerRef.current.scrollLeft)
    setScroll(scroll)
    setAnimating(true)
  }

  return (
    <div className={styles.CoverList} ref={ref}>
      <div className={styles.CoverList_Scroller} ref={scrollerRef}>
        {animating && (
          <Motion
            defaultStyle={{ scroll: currentScroll }}
            style={{ scroll: spring(scroll) }}
            onRest={() => setAnimating(false)}
          >
            {styles => {
              scrollerRef.current.scrollLeft = styles.scroll
              return null
            }}
          </Motion>
        )}
        {nodes.map((node, i) => (
          <a href={`#${node.slug}`} key={i}>
            <Image
              fluid={node.coverImage.childImageSharp.fluid}
              className={stylesImage.CoverImage}
              classNameGhost={stylesImage.CoverImage_Ghost}
            />
            <div className={styles.CoverList_Title}>Pickup: {node.name}</div>
          </a>
        ))}
      </div>
      {index > 0 && (
        <button
          className={styles.isPrev}
          onClick={() => scrollTo(index - 1)}
          aria-label="Previous"
        />
      )}
      {index < nodes.length - 1 && (
        <button
          className={styles.isNext}
          onClick={() => scrollTo(index + 1)}
          aria-label="Next"
        />
      )}
    </div>
  )
}

export default CoverList
