import { Motion, spring } from 'react-motion'
import React, { useState, useRef, useEffect, useContext } from 'react'

import { HeaderVisibilityContext } from './HeaderVisibilityContext'
import isTouchDevice from '../utils/isTouchDevice'
import QuickNavAnchors from './QuickNavAnchors'
import QuickNavTrigger from './QuickNavTrigger'
import styles from './QuickNav.module.css'

const shrinkPosition = position => {
  position = (position - 0.2) / 0.6
  position = Math.max(position, 0)
  position = Math.min(position, 1)
  return position
}

const QuickNav = ({ nodes, hidden }) => {
  const ref = useRef()

  const [active, setActive] = useState(false)
  const [position, setPosition] = useState(0)

  const { setHeaderVisible } = useContext(HeaderVisibilityContext)

  // touch

  const onTouchStart = ({ touches }) => {
    const position = shrinkPosition(touches[0].clientY / window.innerHeight)
    setPosition(position)
    setActive(true)
    setHeaderVisible(false)
  }

  const onTouchMove = ({ touches }) => {
    const position = shrinkPosition(touches[0].clientY / window.innerHeight)
    setPosition(position)
  }

  const onTouchEnd = () => {
    setActive(false)
    setHeaderVisible(true)
  }

  useEffect(() => {
    const el = ref.current
    const onTouchMove = e => e.preventDefault()
    el.addEventListener('touchmove', onTouchMove, { passive: false })
    return () => el.removeEventListener('touchmove', onTouchMove)
  }, [])

  // mouse

  const onMouseEnter = e => {
    const position = shrinkPosition(e.clientY / window.innerHeight)
    setPosition(position)
    setActive(true)
    setHeaderVisible(false)
  }

  const onMouseMove = e => {
    const position = shrinkPosition(e.clientY / window.innerHeight)
    setPosition(position)
  }

  const onMouseLeave = () => {
    setActive(false)
    setHeaderVisible(true)
  }

  return (
    <div
      className={styles.QuickNav}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onTouchCancel={onTouchEnd}
      onMouseEnter={isTouchDevice() ? null : onMouseEnter}
      onMouseMove={isTouchDevice() ? null : onMouseMove}
      onMouseLeave={isTouchDevice() ? null : onMouseLeave}
      ref={ref}
    >
      <Motion
        style={{
          translateX: spring(active ? 100 : hidden ? 100 : 0, {
            stiffness: 260,
            damping: 24
          })
        }}
      >
        {({ translateX }) => (
          <div
            className={styles.QuickNav_Trigger}
            style={{ transform: `translateX(${translateX}%)` }}
          >
            <QuickNavTrigger />
          </div>
        )}
      </Motion>
      <Motion
        style={{
          translateX: spring(active ? 0 : 100, {
            stiffness: 260,
            damping: 24
          })
        }}
      >
        {({ translateX }) => (
          <div
            className={styles.QuickNav_Anchors}
            style={{ transform: `translateX(${translateX}%)` }}
          >
            <QuickNavAnchors
              nodes={nodes}
              position={position}
              active={active}
              onTerminate={() => setActive(false)}
            />
          </div>
        )}
      </Motion>
    </div>
  )
}

export default QuickNav
